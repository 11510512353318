import { useRef } from 'react';
import classNames from 'classnames';
import { Units } from '@reshima/shared';
import { Item, List, updateItemCount, updateItemUnit } from '@reshima/firebase';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useListContext } from '../lists/list-context';
import { UnitSelect } from './unit-select';
import { ItemCount } from './item-count';

export function ListItemCountsUnits({
  list,
  item,
  isEditing,
}: {
  list: List;
  item: Item;
  isEditing: boolean;
}) {
  const name = 'ListItemText';
  const counterRef = useRef<HTMLInputElement>(null);
  const { maxItemsCount, addTask } = useListContext();

  async function onItemCountChange(count: number) {
    const action = Action.Check;

    const previousCount = item.count;

    const properties = {
      listId: list.id,
      itemId: item.id,
      count,
      previousCount,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const redo = () => updateItemCount({ list, itemId: item.id, count });
      const undo = () =>
        updateItemCount({
          list,
          itemId: item.id,
          count: previousCount,
        });

      redo();

      addTask({ redo, undo });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  async function onItemUnitChange({ unit }: { unit: Units }) {
    const action = Action.Check;

    const previousUnit = item.unit;

    const properties = {
      listId: list.id,
      itemId: item.id,
      unit,
      previousUnit,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const redo = () => updateItemUnit({ list, itemId: item.id, unit });
      const undo = () =>
        updateItemUnit({
          list,
          itemId: item.id,
          unit: previousUnit,
        });

      redo();

      addTask({ redo, undo });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  const isCounterFocus = counterRef.current === document.activeElement;

  const showCountsUnits =
    isEditing || item.count > 1 || isCounterFocus || item.unit !== Units.pcs;

  return (
    <div
      className={classNames('flex items-stretch gap-0.5', {
        hidden: !showCountsUnits,
      })}
    >
      <ItemCount
        ref={counterRef}
        count={item.count}
        onChange={onItemCountChange}
        className="text-sm text-right"
        style={{ width: `${maxItemsCount.toString().length}ch` }}
      />
      <UnitSelect
        unit={item.unit}
        onChange={(unit) => onItemUnitChange({ unit })}
        buttonClassName="text-sm py-0.5 px-0.5"
        optionClassName="text-sm"
        chevron={false}
        tight
      />
    </div>
  );
}
