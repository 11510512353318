import classNames from 'classnames';
import { MotionDiv } from '@reshima/shared-ui';
import { Collapsed } from '@reshima/pure-ui';
import { CustomableNamedCategory } from '@reshima/category';
import { Dictionary } from '@reshima/translations';
import { useTranslations } from '@reshima/translations-ui';
import { Item } from '@reshima/firebase';

function getSubTitle({
  items,
  dictionary,
}: {
  items: Item[];
  dictionary: Dictionary;
}) {
  if (items.length === 1) {
    return dictionary.list.itemTitle;
  }

  return `${items.length} ${dictionary.list.itemsTitle}`;
}

export function SortingListItemsCategory({
  category: { name, icon },
  items,
}: {
  category: CustomableNamedCategory;
  items: Item[];
}) {
  const dictionary = useTranslations();

  const subTitle = getSubTitle({ items, dictionary });

  return (
    <Collapsed
      container={
        <div className="w-full flex items-center p-2 gap-1 xs:gap-2">
          {/* Specify width because of custom categories */}
          <div className="text-xl w-8">{icon}</div>
          <div
            className={classNames(
              'flex flex-1',
              'flex-col 2xs:flex-row',
              'items-center justify-between',
            )}
          >
            <span className="text-lg text-center">{name}</span>
            <div className="shrink-0 text-sm font-light text-gray-500">
              <MotionDiv
                key={subTitle}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
              >
                {subTitle}
              </MotionDiv>
            </div>
          </div>
        </div>
      }
    >
      <div className="flex flex-wrap gap-1 pb-2">
        {items.map(({ id, name }) => (
          <div key={id} className="px-2 border border-base-300 rounded-md">
            {name}
          </div>
        ))}
      </div>
    </Collapsed>
  );
}
