import { forwardRef } from 'react';
import classNames from 'classnames';
import { useTranslations } from '@reshima/translations-ui';
import { maxItemCount } from '../shared/counts-and-units';

type Props = {
  count: number;
  className?: string;
  style?: React.CSSProperties;
  onChange: (count: number) => void;
  onEnter?: () => void;
};

export const ItemCount = forwardRef<HTMLInputElement, Props>(function ItemCount(
  { count, className, style, onChange, onEnter }: Props,
  ref,
) {
  const { ariaLabel } = useTranslations()['item-count'];

  return (
    <input
      ref={ref}
      type="number"
      inputMode="numeric"
      aria-label={ariaLabel}
      className={classNames(
        'box-content',
        'py-1 px-0.5 xs:px-1',
        'text-center',
        'bg-transparent',
        'rounded-md border-none focus-within:ring-2 focus-within:ring-blue-500',
        '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
        className,
      )}
      style={style}
      value={count}
      onFocus={(e) => e.target.select()}
      onBlur={() => {
        if (count < 1) return onChange(1);
        if (count > maxItemCount) return onChange(maxItemCount);
      }}
      onChange={(e) => {
        const value = +e.target.value;
        if (value > maxItemCount) return onChange(maxItemCount);
        return onChange(value);
      }}
      onKeyDown={({ key }) => {
        if (key === 'Enter') {
          onEnter?.();
        }
      }}
    />
  );
});
