import { Suspense } from 'react';
import { Dictionary } from '@reshima/translations';
import { ReshimaPage } from '@reshima/shared-ui';
import { ListCategories } from '../lib/list-categories';

export function ListCategoriesPage({ dictionary }: { dictionary: Dictionary }) {
  const { heading, description, previousPageAriaLabel } =
    dictionary['list-categories'];

  return (
    <ReshimaPage
      name="ListCategoriesPage"
      heading={heading}
      description={description}
      previousPage={{
        href: '/list',
        ariaLabel: previousPageAriaLabel,
        keepSearch: true,
      }}
    >
      <Suspense>
        <ListCategories />
      </Suspense>
    </ReshimaPage>
  );
}
