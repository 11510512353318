import {
  ClipboardEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { AppUser } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { debouncedCallback, Units } from '@reshima/shared';
import {
  categoriesMap,
  CustomableCategoryId,
  CustomCategory,
} from '@reshima/category';
import { CategoriesSelect, CategoryIcon } from '@reshima/categories-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { fetchSuggestCategory } from '@reshima/category-search';
import { Pencil } from '../pencil';
import {
  MeasuredItemInput,
  MeasuredItemInputHandler,
} from '../add-item/measured-item-input';

type Props = {
  freeTextItemName: string;
  cleanedItemName: string;
  itemUnit: Units;
  itemCount: number;
  itemCategoryId: CustomableCategoryId | undefined;
  fixedCategoriesOrder: CustomableCategoryId[];
  customCategories: Record<string, CustomCategory>;
  parseItemCount: boolean;
  parseItemUnit: boolean;
  onItemNameChange: (args: {
    freeTextItemName: string;
    cleanedItemName: string;
  }) => void;
  onItemUnitChange: (unit: Units) => void;
  onItemCountChange: (count: number) => void;
  onItemCategoryIdChange: (categoryId?: CustomableCategoryId) => void;
  onEnter?: () => void;
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
  className?: string;
};

export type CategorizedMeasuredItemInputHandler = {
  reset: () => void;
  focusItemName: () => void;
};

export const CategorizedMeasuredItemInput = forwardRef(
  function CategorizedMeasuredItemInput(
    {
      freeTextItemName,
      cleanedItemName,
      itemUnit,
      itemCount,
      itemCategoryId,
      fixedCategoriesOrder,
      customCategories,
      parseItemCount,
      parseItemUnit,
      onItemNameChange,
      onItemUnitChange,
      onItemCountChange,
      onItemCategoryIdChange,
      onEnter,
      onPaste,
      className,
    }: Props,
    ref: React.Ref<CategorizedMeasuredItemInputHandler>,
  ) {
    const { automaticCategorySelection } = useTranslations()['add-item'];

    const [isCategoryManualUpdated, setIsCategoryManualUpdated] =
      useState(false);
    const [isSuggestingCategory, setIsSuggestingCategory] = useState(false);
    const [lastSuggestedCategory, setLastSuggestedCategory] =
      useState<CustomableCategoryId>();
    const measuredItemInputRef = useRef<MeasuredItemInputHandler>(null);
    const itemNameRef = useRef('');
    const { user } = useClientAuth();

    useImperativeHandle(ref, () => ({
      reset: () => {
        setIsCategoryManualUpdated(false);
        setIsSuggestingCategory(false);
        setLastSuggestedCategory(undefined);
        measuredItemInputRef.current?.reset();
      },
      focusItemName: () => measuredItemInputRef.current?.focusItemName(),
    }));

    // TODO: fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSuggestCategory = useCallback(
      debouncedCallback({
        callback: async ({
          itemName,
          user,
        }: {
          itemName: string;
          user: AppUser;
        }) => {
          const categoryId = await fetchSuggestCategory({
            search: itemName,
            user,
          });

          if (itemName === itemNameRef.current) {
            setIsSuggestingCategory(false);
            setLastSuggestedCategory(categoryId);
            onItemCategoryIdChange(categoryId);
          }
        },
        delay: 300,
      }),
      [],
    );

    const suggestCategory = useCallback(
      ({ itemName, user }: { itemName: string; user: AppUser }) => {
        onItemCategoryIdChange(undefined);
        if (!itemName) {
          setIsSuggestingCategory(false);
        } else {
          setIsSuggestingCategory(true);
          debouncedSuggestCategory({ itemName, user });
        }
      },
      [debouncedSuggestCategory, onItemCategoryIdChange],
    );

    const _onItemCategoryChange = useCallback(
      (categoryId: CustomableCategoryId) => {
        onItemCategoryIdChange(categoryId);
        setIsCategoryManualUpdated(!!categoryId);
        if (!categoryId && user) {
          suggestCategory({ itemName: cleanedItemName, user });
        }
      },
      [cleanedItemName, onItemCategoryIdChange, suggestCategory, user],
    );

    const _onItemNameChange = useCallback(
      ({
        freeTextItemName,
        cleanedItemName,
      }: {
        freeTextItemName: string;
        cleanedItemName: string;
      }) => {
        onItemNameChange({
          freeTextItemName,
          cleanedItemName,
        });
        if (!isCategoryManualUpdated && user) {
          suggestCategory({ itemName: cleanedItemName, user });
        }
      },
      [onItemNameChange, isCategoryManualUpdated, user, suggestCategory],
    );

    useEffect(() => {
      itemNameRef.current = cleanedItemName;
    }, [cleanedItemName]);

    return (
      <div
        className={classNames(
          'w-full flex-1',
          'text-lg',
          'flex items-center',
          'border rounded-lg bg-base-100 border-base-300',
          'focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500',
          className,
        )}
      >
        <CategoriesSelect
          categoryId={
            isSuggestingCategory ? lastSuggestedCategory : itemCategoryId
          }
          categoriesOrder={fixedCategoriesOrder}
          customCategories={customCategories}
          round={false}
          chevron={false}
          onCategoryIdChange={_onItemCategoryChange}
          placement="left"
          className={classNames(
            'box-content size-6 p-1',
            'flex items-center justify-center',
            'border border-transparent rounded-s-md',
            {
              'animate-quick-pulse': isSuggestingCategory,
            },
          )}
          emptyOption={{
            icon: '✨',
            label: automaticCategorySelection,
          }}
          placeholder={
            itemCategoryId === categoriesMap.Loading.id ? (
              <CategoryIcon categoryId={itemCategoryId} customCategories={{}} />
            ) : (
              <Pencil className="w-full scale-x-[-1]" />
            )
          }
        />
        <MeasuredItemInput
          ref={measuredItemInputRef}
          itemName={freeTextItemName}
          onItemNameChange={_onItemNameChange}
          onItemCountChange={onItemCountChange}
          onItemUnitChange={onItemUnitChange}
          itemCount={itemCount}
          itemUnit={itemUnit}
          parseItemCount={!!parseItemCount}
          parseItemUnit={!!parseItemUnit}
          onEnter={() => onEnter?.()}
          onPaste={(e) => onPaste?.(e)}
        />
      </div>
    );
  },
);
