import { AiOutlinePlus } from 'react-icons/ai';
import { Button } from '@reshima/pure-ui';
import { List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { useListContext } from '../../lists/list-context';
import { AddItemsModal } from './add-items-modal';

export function AddItemsButton({ list }: { list: List }) {
  const name = 'AddItemsButton';
  const { heading, button } = useTranslations()['add-items-modal'];
  const { addTask } = useListContext();
  const { showModal } = useModal();

  const properties = { listId: list.id };

  function onClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      content: <AddItemsModal list={list} addTask={addTask} />,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button className="flex items-center gap-2" onClick={onClick} ghost>
      <AiOutlinePlus className="text-lg" />
      {button}
    </Button>
  );
}
