import { useCallback } from 'react';
import { CustomableCategoryId } from '@reshima/category';
import { Item, List, manualUpdateCategorizedItem } from '@reshima/firebase';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { CategoriesPickerButton } from '@reshima/categories-ui';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { useListContext } from '../lists/list-context';

export function ListItemIconButton({ list, item }: { list: List; item: Item }) {
  const name = 'ListItemIconButton';

  const { buttonAriaLabel } = useTranslations()['update-list-item-category'];
  const { addTask } = useListContext();
  const { user } = useClientAuth();

  const onCategoryIdSelect = useCallback(
    async (categoryId: CustomableCategoryId) => {
      const action = Action.Update;

      const oldCategoryId = item.categoryId;

      const properties = {
        listId: list.id,
        itemId: item.id,
        itemName: item.name,
        oldCategoryId,
        newCategoryId: categoryId,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        const redo = () =>
          manualUpdateCategorizedItem({
            list,
            item,
            categoryId,
            user,
          });
        const undo = () =>
          manualUpdateCategorizedItem({
            list,
            item,
            categoryId: oldCategoryId,
            user,
          });

        redo();

        addTask({
          redo,
          undo,
        });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [addTask, item, list, user],
  );

  return (
    <CategoriesPickerButton
      categoryId={item.categoryId}
      categoriesOrder={list.fixedCategoriesOrder}
      customCategories={list.customCategories}
      ariaLabel={`${buttonAriaLabel} ${item.name}`}
      onCategoryIdSelect={onCategoryIdSelect}
    />
  );
}
