import { useCallback, useEffect, useState, ClipboardEvent } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import {
  AppUser,
  List,
  NewItem,
  addItems,
  deleteItems,
  restoreItems,
} from '@reshima/firebase';
import { Button, Input } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { ItemActivityType } from '@reshima/list-activity-shared';
import { useClientAuth } from '@reshima/client-auth-ui';
import { fetchSuggestCategory } from '@reshima/category-search';
import { ListContext } from '../../lists/list-context';
import { getPastedItems } from './utils';
import { CategorizedMeasuredItemInput } from './categorized-measured-item-input';

export type ModifiedNewItem = Omit<NewItem, 'itemName'> & {
  freeTextItemName: string;
  cleanedItemName: string;
};

export function AddItemsModal({
  list,
  items,
  addTask,
}: {
  list: List;
  items?: ModifiedNewItem[];
  addTask: ListContext['addTask'];
}) {
  const name = 'AddItemsModal';
  const {
    initDescription,
    alternativeDescription,
    pastingInputPlaceholder,
    pastingInputAriaLabel,
    removeItemAriaLabel,
    addItemButton,
    cancelButton,
  } = useTranslations()['add-items-modal'];
  const properties = { listId: list.id };

  const [modifiedItems, setModifiedItems] = useState(items);
  const [isRendered, setIsRendered] = useState(false); // Remove this might lead to duplicate suggested categories
  const [adding, setAdding] = useState(false);
  const { closeModal } = useModal();
  const { userData, user } = useClientAuth();

  const { fixedCategoriesOrder, customCategories } = list;

  const { parseItemCount, parseItemUnit } = userData?.settings.addingItem
    .autoComplete || {
    parseItemCount: true,
    parseItemUnit: true,
  };

  async function onAddClick({ items }: { items: ModifiedNewItem[] }) {
    const action = Action.Reset;

    setAdding(true);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const addedItems = addItems({
        list,
        items: items.map((item) => ({
          ...item,
          itemName: item.cleanedItemName,
        })),
      });

      const redo = () =>
        restoreItems({
          list,
          items: addedItems,
        });
      const undo = () =>
        deleteItems({
          list,
          items: addedItems,
        });

      addTask({
        redo,
        undo,
        activity: {
          items: addedItems,
          type: ItemActivityType.added,
        },
      });

      closeModal();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }

    setAdding(false);
  }

  const updateItem = useCallback(
    ({ item, index }: { item: Partial<ModifiedNewItem>; index: number }) => {
      setModifiedItems((prevItems) => {
        if (!prevItems) return prevItems;
        return prevItems.map((prevItem, i) =>
          i === index ? { ...prevItem, ...item } : prevItem,
        );
      });
    },
    [],
  );

  const suggestCategories = useCallback(
    async ({ items, user }: { items: ModifiedNewItem[]; user: AppUser }) => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        const categoryId = await fetchSuggestCategory({
          search: item.cleanedItemName,
          user,
        });

        updateItem({
          item: { categoryId },
          index: i,
        });
      }
    },
    [updateItem],
  );

  const onPaste = useCallback(
    (event: ClipboardEvent) => {
      event.preventDefault();

      const items = getPastedItems({
        event,
        parseItemCount,
        parseItemUnit,
      });

      setModifiedItems(items);

      if (user) {
        suggestCategories({ items, user });
      }
    },
    [parseItemCount, parseItemUnit, suggestCategories, user],
  );

  useEffect(() => {
    if (!user || !items || !isRendered) return;
    suggestCategories({ items, user });
  }, [items, isRendered, suggestCategories, user]);

  useEffect(() => {
    setIsRendered(true);
  }, [setIsRendered]);

  useEffect(() => {
    if (modifiedItems && modifiedItems.length < 1) {
      closeModal();
    }
  }, [closeModal, modifiedItems]);

  if (!modifiedItems) {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div>
            <p>{initDescription}</p>
            <p>{alternativeDescription}</p>
          </div>
          <Input
            type="text"
            placeholder={pastingInputPlaceholder}
            ariaLabel={pastingInputAriaLabel}
            autoFocus
            onPaste={onPaste}
          />
        </div>
        <div className="flex justify-center">
          <Button onClick={closeModal} secondary>
            {cancelButton}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 min-h-0">
      <div className="flex flex-col gap-2 px-0.5 py-0.5 overflow-y-auto">
        {modifiedItems.map(
          (
            { freeTextItemName, cleanedItemName, count, unit, categoryId },
            index,
          ) => (
            <div className="flex gap-1" key={index}>
              <CategorizedMeasuredItemInput
                freeTextItemName={freeTextItemName}
                cleanedItemName={cleanedItemName}
                itemCount={count}
                itemUnit={unit}
                itemCategoryId={categoryId}
                fixedCategoriesOrder={fixedCategoriesOrder}
                customCategories={customCategories}
                parseItemCount={parseItemCount}
                parseItemUnit={parseItemUnit}
                onItemNameChange={(item) =>
                  updateItem({
                    item,
                    index,
                  })
                }
                onItemCountChange={(count) =>
                  updateItem({
                    item: { count },
                    index,
                  })
                }
                onItemUnitChange={(unit) =>
                  updateItem({
                    item: { unit },
                    index,
                  })
                }
                onItemCategoryIdChange={(categoryId) =>
                  updateItem({
                    item: { categoryId },
                    index,
                  })
                }
              />
              <Button
                className="text-xl"
                onClick={() =>
                  setModifiedItems(modifiedItems.filter((_, i) => i !== index))
                }
                ariaLabel={`${removeItemAriaLabel} ${cleanedItemName}`}
                ghost
                circle
              >
                <div className="p-1">
                  <AiOutlineDelete />
                </div>
              </Button>
            </div>
          ),
        )}
      </div>
      <div className="flex justify-center gap-2">
        <Button
          onClick={() => onAddClick({ items: modifiedItems })}
          disabled={adding || modifiedItems.length < 1}
        >
          {addItemButton}
        </Button>
        <Button onClick={closeModal} secondary>
          {cancelButton}
        </Button>
      </div>
    </div>
  );
}
