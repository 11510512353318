import { Reorder, useDragControls } from 'framer-motion';
import { RxCaretSort } from 'react-icons/rx';
import { CategoryItems } from '@reshima/firebase';
import { CustomableCategoryId } from '@reshima/category';
import { SortingListItemsCategory } from './sorting-list-items-category';

function Category({
  categoryItems: { category, categoryItems },
}: {
  categoryItems: CategoryItems;
}) {
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      value={category.id}
      key={category.id}
      dragListener={false}
      dragControls={dragControls}
      transition={{ duration: 0.2 }}
    >
      <div className="flex items-center bg-base-100 border border-base-300 rounded-xl shadow-xs">
        <div
          className="cursor-grab touch-none p-2"
          onPointerDown={(event) => {
            event.preventDefault();
            dragControls.start(event);
          }}
        >
          <RxCaretSort className="text-xl" />
        </div>
        <SortingListItemsCategory category={category} items={categoryItems} />
      </div>
    </Reorder.Item>
  );
}

export function SortingListItemsCategories({
  categoriesItems,
  onReorder,
}: {
  categoriesItems: CategoryItems[];
  onReorder: (order: CustomableCategoryId[]) => void;
}) {
  return (
    <Reorder.Group
      axis="y"
      values={categoriesItems.map(({ category }) => category.id)}
      onReorder={onReorder}
    >
      <div className="flex w-full flex-col gap-2">
        {categoriesItems.map((categoryItems) => (
          <Category
            key={categoryItems.category.id}
            categoryItems={categoryItems}
          />
        ))}
      </div>
    </Reorder.Group>
  );
}
