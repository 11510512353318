import { trim, Units } from '@reshima/shared';
import {
  extractMeasurementWords,
  removeMeasurementWords,
} from '@reshima/hebrew';

export const maxItemCount = 999;

function removeCount({
  itemName,
  itemCount,
}: {
  itemName: string;
  itemCount: number;
}) {
  const removedCount = itemName.slice(itemCount.toString().length);
  return trim(removedCount);
}

function removeUnit(itemName: string) {
  const removedUnit = removeMeasurementWords(itemName);
  return trim(removedUnit);
}

export function extractUnit({ itemName }: { itemName: string }): Units {
  const measurementWords = extractMeasurementWords({
    text: itemName,
  });

  if (measurementWords.length === 1) return measurementWords[0];

  return Units.pcs;
}

export function extractCount({
  itemName,
}: {
  itemName: string;
}): number | undefined {
  const matchArray = itemName.match(new RegExp(`^(\\d+(\\.\\d)?) |$`));

  const match = matchArray?.[1];

  if (!match) return undefined;

  const count = parseFloat(match) || 1;

  return Math.min(count, maxItemCount);
}

export function cleanCountFromItemName({
  itemName,
  itemCount,
}: {
  itemName: string;
  itemCount: number;
}): string {
  const count = extractCount({ itemName });

  if (count === itemCount) {
    return removeCount({ itemName, itemCount });
  }

  return trim(itemName);
}

export function cleanUnitFromItemName({
  itemName,
  itemUnit,
}: {
  itemName: string;
  itemUnit: Units;
}): string {
  const unit = extractUnit({ itemName });

  if (unit === itemUnit) {
    return removeUnit(itemName);
  }

  return trim(itemName);
}

export function parseItemName({
  freeTextItemName,
  parseItemCount,
  parseItemUnit,
}: {
  freeTextItemName: string;
  parseItemCount: boolean;
  parseItemUnit: boolean;
}): {
  cleanedItemName: string;
  itemCount: number;
  itemUnit: Units;
} {
  let cleanedItemName = freeTextItemName;
  let itemCount: number | undefined;
  let itemUnit = Units.pcs;

  if (parseItemCount) {
    itemCount = extractCount({ itemName: freeTextItemName });

    if (itemCount) {
      cleanedItemName = cleanCountFromItemName({
        itemName: cleanedItemName,
        itemCount,
      });
    }
  }

  if (parseItemUnit) {
    itemUnit = extractUnit({ itemName: freeTextItemName });

    cleanedItemName = cleanUnitFromItemName({
      itemName: cleanedItemName,
      itemUnit,
    });
  }

  return {
    cleanedItemName,
    itemCount: itemCount || 1,
    itemUnit,
  };
}
