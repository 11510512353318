import classNames from 'classnames';
import { MotionDiv } from '@reshima/shared-ui';
import { Item, List } from '@reshima/firebase';
import { Collapsed } from '@reshima/pure-ui';
import { Dictionary } from '@reshima/translations';
import { useTranslations } from '@reshima/translations-ui';
import { ListItemsTableChecked } from './list-items-table-checked';
import { ListItemsCompletedActionsDropdown } from './list-items-completed-actions-dropdown';
import { ListItemsCompletedSortingDirection } from './list-items-completed-sorting-direction';
import { ListItemsCompletedSortingDropdown } from './list-items-completed-sorting-dropdown';

function getSubTitle({
  completedItems,
  dictionary,
}: {
  completedItems: Item[];
  dictionary: Dictionary;
}) {
  if (completedItems.length === 1) {
    return dictionary.list.itemTitle;
  }

  return `${completedItems.length} ${dictionary.list.itemsTitle}`;
}

export function ListItemsCompleted({
  list,
  completedItems,
  className,
}: {
  list: List;
  completedItems: Item[];
  className?: string;
}) {
  const dictionary = useTranslations();
  const { completedItemsIconAriaLabel } = dictionary.list;
  const subTitle = getSubTitle({ completedItems, dictionary });

  if (completedItems.length === 0) {
    return null;
  }

  return (
    <Collapsed
      className={classNames(
        'border border-base-300 rounded-xl shadow-xs',
        className,
      )}
      container={
        <div className="w-full flex items-center p-2 gap-1 xs:gap-2">
          <span
            className="text-xl"
            role="img"
            aria-label={completedItemsIconAriaLabel}
          >
            ✅
          </span>
          <div
            className={classNames(
              'flex flex-1',
              'flex-col xs:flex-row',
              'items-center justify-between',
            )}
          >
            <span className="text-lg text-center">
              {dictionary.list.completedItems}
            </span>
            <div className="shrink-0 text-sm font-light text-gray-500">
              <MotionDiv
                key={subTitle}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
              >
                {subTitle}
              </MotionDiv>
            </div>
          </div>
        </div>
      }
    >
      <div className="px-1 2xs:px-2 xs:px-3 pb-1">
        <div className="flex justify-between items-center">
          <ListItemsCompletedActionsDropdown
            completedItems={completedItems}
            list={list}
          />
          <div className="flex items-stretch">
            <ListItemsCompletedSortingDirection list={list} />
            <ListItemsCompletedSortingDropdown list={list} />
          </div>
        </div>
        <ListItemsTableChecked list={list} items={completedItems} />
      </div>
    </Collapsed>
  );
}
