'use client';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { getListStream, List } from '@reshima/firebase';
import { useNavigate, useSearchParams } from '@reshima/navigation-ui';
import { useManagedSyncStatus } from '@reshima/nav-bar-ui';
import {
  trackEvent,
  Action,
  ActionModifier,
  trackException,
} from '@reshima/telemetry';
import { useTranslations } from '@reshima/translations-ui';
import { AddCategory } from './add-category';
import { ListCategoriesTable } from './list-categories-table';

export function ListCategories() {
  const name = 'ListCategories';

  const [list, setList] = useState<List>();
  const [isListLoading, setIsListLoading] = useState(true);
  const [isListError, setIsListError] = useState(false);

  const {
    'list-categories': { error },
    'list-text': { defaultListName },
  } = useTranslations();

  const params = useSearchParams();
  const navigate = useNavigate();
  const { setSyncByStreamSource } = useManagedSyncStatus();

  const listId = params.get('id') || '';

  const listenListStream = useCallback(
    ({ listId }: { listId: string }) => {
      const action = Action.Fetch;

      const properties = {
        listId,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      return getListStream({
        listId,
        defaultListName,
        onListUpdate: ({ list, source }) => {
          setList(list);
          setSyncByStreamSource(source);
          setIsListLoading(false);
          setIsListError(false);
          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            properties,
            start,
          });
        },
        onError: (error) => {
          setIsListLoading(false);
          setIsListError(true);
          trackException({
            name,
            action,
            properties,
            start,
            error,
          });
        },
      });
    },
    [defaultListName, setSyncByStreamSource],
  );

  useEffect(() => {
    if (!listId) {
      trackEvent({
        name,
        action: Action.Load,
        actionModifier: ActionModifier.NotFound,
        properties: { listId },
      });

      navigate('/lists');
    } else {
      return listenListStream({ listId });
    }
  }, [listId, listenListStream, navigate]);

  if (!listId) {
    return null;
  }

  if (isListLoading || !list) {
    return (
      <div className="flex flex-col items-center" data-testid="loading">
        <AiOutlineLoading className="text-3xl animate-spin" />
      </div>
    );
  }

  if (isListError) {
    return (
      <div className="flex flex-col items-center">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-xs w-full mx-auto flex flex-col gap-4">
      <AddCategory listId={listId} />
      <ListCategoriesTable
        listId={listId}
        customCategories={list.customCategories}
      />
    </div>
  );
}
