import { List } from '@reshima/firebase';
import { ListCategoryItem } from './list-category-item';
import { useTranslations } from '@reshima/translations-ui';

export function ListCategoriesTable({
  listId,
  customCategories,
}: {
  listId: string;
  customCategories: List['customCategories'];
}) {
  const { emptyCategories } = useTranslations()['list-categories'];

  const sortedCategories = Object.values(customCategories).sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
  );

  return (
    <div className="flex flex-col items-center gap-2">
      {!sortedCategories.length ? (
        <div>{emptyCategories}</div>
      ) : (
        sortedCategories.map((category) => (
          <ListCategoryItem
            key={category.id}
            category={category}
            listId={listId}
          />
        ))
      )}
    </div>
  );
}
