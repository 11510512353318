import { useCallback, useState } from 'react';
import { addCustomCategory } from '@reshima/firebase';
import { Button, Input } from '@reshima/pure-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useTranslations } from '@reshima/translations-ui';
import { categoryIconMaxLength } from './constants';

export function AddCategory({ listId }: { listId: string }) {
  const name = 'AddCategory';

  const [categoryName, setCategoryName] = useState('');
  const [categoryIcon, setCategoryIcon] = useState('');

  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isAddCategoryError, setIsAddCategoryError] = useState(false);

  const {
    addCategory: {
      button,
      error,
      iconPlaceholder,
      iconAriaLabel,
      namePlaceholder,
      nameAriaLabel,
    },
  } = useTranslations()['list-categories'];

  const onAddCategoryClick = useCallback(async () => {
    const action = Action.Create;

    setIsAddingCategory(true);
    setIsAddCategoryError(false);

    const properties = {
      listId,
      categoryName,
      categoryIcon,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      await addCustomCategory({
        listId,
        category: {
          name: categoryName,
          icon: categoryIcon,
        },
      });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });

      setCategoryName('');
      setCategoryIcon('');
    } catch (error) {
      setIsAddCategoryError(true);

      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    } finally {
      setIsAddingCategory(false);
    }
  }, [categoryName, categoryIcon, listId]);

  const buttonEnabled = !isAddingCategory && categoryName && categoryIcon;

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col xs:flex-row items-start xs:items-center gap-2">
        <div className="w-full flex flex-1 items-center gap-2">
          <Input
            type="text"
            className="w-12"
            value={categoryIcon}
            placeholder={iconPlaceholder}
            ariaLabel={iconAriaLabel}
            onChange={setCategoryIcon}
            onKeyDown={({ key }) => {
              if (key === 'Enter' && buttonEnabled) {
                onAddCategoryClick();
              }
            }}
            maxLength={categoryIconMaxLength}
          />
          <Input
            type="text"
            className="w-full"
            value={categoryName}
            placeholder={namePlaceholder}
            ariaLabel={nameAriaLabel}
            onChange={setCategoryName}
            onKeyDown={({ key }) => {
              if (key === 'Enter' && buttonEnabled) {
                onAddCategoryClick();
              }
            }}
          />
        </div>
        <Button
          className="w-full xs:w-auto"
          onClick={onAddCategoryClick}
          disabled={!buttonEnabled}
        >
          {button}
        </Button>
      </div>
      {isAddCategoryError && <div>{error}</div>}
    </div>
  );
}
