import { ClipboardEvent } from 'react';
import { categoriesMap } from '@reshima/category';
import { parseItemName } from '../../shared/counts-and-units';
import { ModifiedNewItem } from './add-items-modal';

export function getPastedItems({
  event: { clipboardData },
  parseItemCount,
  parseItemUnit,
}: {
  event: ClipboardEvent;
  parseItemCount: boolean;
  parseItemUnit: boolean;
}): ModifiedNewItem[] {
  const text = clipboardData.getData('text/plain');

  const items: ModifiedNewItem[] = text
    .split('\n')
    .map((itemName) => itemName.trim())
    .filter(Boolean)
    .map((freeTextItemName) => {
      const { cleanedItemName, itemCount, itemUnit } = parseItemName({
        freeTextItemName,
        parseItemCount,
        parseItemUnit,
      });

      return {
        freeTextItemName,
        cleanedItemName,
        count: itemCount,
        unit: itemUnit,
        categoryId: categoriesMap.Loading.id,
      };
    });

  return items;
}
