import { HiOutlineDotsVertical } from 'react-icons/hi';
import { isListAdmin, Item, List } from '@reshima/firebase';
import { Dropdown } from '@reshima/pure-ui';
import { ListParticipantsButton, ShareListLink } from '@reshima/share-list-ui';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { ListActivityButton } from '@reshima/list-activity-ui';
import { ListCategoriesButton } from '@reshima/list-categories-ui';
import { CheckListItemsButton } from './check-list-items-button';
import { DeleteListButton } from './delete-list-button';
import { DeleteListItemsButton } from './delete-list-items-button';
import { DownloadListButton } from './download-list-button';
import { LeaveListButton } from './leave-list-button';
import { RecategorizeListItemsButton } from './recategorize-list-items-button';
import { ListRenameButton } from './list-rename-button';
import { ListShortcutButton } from './list-shortcut-button';
import { AddItemsButton } from '../items/add-item/add-items-button';

function ParticipatingListActions({
  list,
  onLeave,
  onDelete,
}: {
  list: List;
  onLeave: () => void;
  onDelete: () => void;
}) {
  const { user } = useClientAuth();

  if (!user) {
    return null;
  }

  return isListAdmin({ list, user }) ? (
    <DeleteListButton list={list} onDelete={onDelete} />
  ) : (
    <LeaveListButton list={list} onLeave={onLeave} />
  );
}

function AdminListActions({ list, items }: { list: List; items: Item[] }) {
  const { userData } = useClientAuth();

  if (!userData?.isAdmin) {
    return null;
  }

  return <RecategorizeListItemsButton list={list} items={items} />;
}

export function ListActionsDropdown({
  list,
  items,
  existsItems,
  onLeave,
  onDelete,
}: {
  list: List;
  items: Item[];
  existsItems: Item[];
  onLeave: () => void;
  onDelete: () => void;
}) {
  const { ariaLabel } = useTranslations()['list-actions-dropdown'];

  return (
    <Dropdown
      ghost
      circle
      placement="right"
      ariaLabel={ariaLabel}
      dataTestId="list-actions-menu-content"
      container={
        <div className="p-1.5">
          <HiOutlineDotsVertical className="text-2xl" />
        </div>
      }
    >
      <div className="flex flex-col items-stretch">
        <ListRenameButton list={list} />
        <ListShortcutButton list={list} />
        <ShareListLink list={list} />
        {list.sharedWith.length > 0 || list.admins.length > 1 ? (
          <ListParticipantsButton listId={list.id} />
        ) : null}
        <ListCategoriesButton listId={list.id} />
        <ListActivityButton listId={list.id} />
        <AddItemsButton list={list} />
        <CheckListItemsButton list={list} items={existsItems} />
        <DeleteListItemsButton list={list} items={items} />
        <DownloadListButton list={list} />
        <ParticipatingListActions
          list={list}
          onLeave={onLeave}
          onDelete={onDelete}
        />
        <AdminListActions list={list} items={items} />
      </div>
    </Dropdown>
  );
}
