import {
  Item,
  List,
  defaultListSortByDirection,
  getSortedItems,
  sortItemsByCheckedUpdatedAtDesc,
} from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { ListItemsTable } from './list-items-table';
import { useItemTracker } from '../hooks/useItemTracker';

export function ListItemsTableFixedCategorized({
  list,
  items,
}: {
  list: List;
  items: Item[];
}) {
  const { categories: categoriesDictionary } = useTranslations();
  const { newItems } = useItemTracker({ items, duration: 2500 });

  const existingItems = items.filter(
    (item) => !newItems.some((newItem) => newItem.id === item.id),
  );

  const existingSortedItems = getSortedItems({
    items: existingItems,
    customCategories: list.customCategories,
    categoriesDictionary,
    categoriesOrder: list.fixedCategoriesOrder,
  });

  if (list.sortByDirection !== defaultListSortByDirection) {
    existingSortedItems.reverse();
  }

  const newSortedItems = newItems.sort(sortItemsByCheckedUpdatedAtDesc);

  const mergedItems = [...newSortedItems, ...existingSortedItems];

  return <ListItemsTable list={list} items={mergedItems} />;
}
