import { useCallback, useEffect, useRef } from 'react';
import { useIsOnline } from '@reshima/shared-ui';
import { AppUser, categoryItem, Item, List } from '@reshima/firebase';
import { categoriesMap } from '@reshima/category';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { useClientAuth } from '@reshima/client-auth-ui';

type CalculatedItems = Record<string, true>;

const name = 'useUpdateItemsLoadingCategories';

export function useUpdateItemsLoadingCategories({
  list,
  items,
}: {
  list?: List;
  items?: Item[];
}) {
  const calculatedItems = useRef<CalculatedItems>({});
  const { isOnline } = useIsOnline();
  const { user } = useClientAuth();

  const updateItemsLoadingCategories = useCallback(
    async ({
      list,
      items,
      user,
    }: {
      list: List;
      items: Item[];
      user: AppUser;
    }) => {
      const action = 'UpdateItemsLoadingCategories';

      const { id: listId } = list;
      const properties = { listId };

      const loadingItems = items.filter(
        ({ id, categoryId }) =>
          categoryId === categoriesMap.Loading.id &&
          !calculatedItems.current[id],
      );

      if (!loadingItems.length) {
        return;
      }

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        for (const item of loadingItems) {
          calculatedItems.current[item.id] = true;
          await categoryItem({
            list,
            itemId: item.id,
            itemName: item.name,
            user,
          });
        }

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties: {
            ...properties,
            itemsCount: loadingItems.length,
          },
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [],
  );

  useEffect(() => {
    if (isOnline && list && items && user) {
      updateItemsLoadingCategories({ list, items, user });
    }
  }, [isOnline, list, items, user, updateItemsLoadingCategories]);
}
