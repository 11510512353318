import {
  ClipboardEventHandler,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Units } from '@reshima/shared';
import { maxItemNameLength } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { parseItemName } from '../../shared/counts-and-units';
import { UnitSelect } from '../unit-select';
import { ItemCount } from '../item-count';

type Props = {
  itemName: string;
  itemUnit: Units;
  itemCount: number;
  parseItemCount: boolean;
  parseItemUnit: boolean;
  onItemNameChange: (args: {
    freeTextItemName: string;
    cleanedItemName: string;
  }) => void;
  onItemUnitChange: (unit: Units) => void;
  onItemCountChange: (count: number) => void;
  onEnter: () => void;
  onPaste: ClipboardEventHandler<HTMLInputElement>;
  className?: string;
};

export type MeasuredItemInputHandler = {
  focusItemName: () => void;
  reset: () => void;
};

export const MeasuredItemInput = forwardRef(function MeasuredItemInput(
  {
    itemName,
    itemUnit,
    itemCount,
    parseItemCount,
    parseItemUnit,
    onItemNameChange,
    onItemUnitChange,
    onItemCountChange,
    className,
    onEnter,
    onPaste,
  }: Props,
  ref: React.Ref<MeasuredItemInputHandler>,
) {
  const { addItemPlaceholder } = useTranslations()['add-item'];
  const [isManualUpdated, setIsManualUpdated] = useState(false);
  const itemNameInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focusItemName: () => itemNameInputRef.current?.focus(),
    reset: () => setIsManualUpdated(false),
  }));

  const onCountChange = useCallback(
    (count: number) => {
      onItemCountChange(count);
      setIsManualUpdated(true);
    },
    [onItemCountChange],
  );

  const onUnitChange = useCallback(
    (unit: Units) => {
      onItemUnitChange(unit);
      setIsManualUpdated(true);
    },
    [onItemUnitChange],
  );

  const onNameChange = useCallback(
    (freeTextItemName: string) => {
      if (isManualUpdated) {
        onItemNameChange({
          freeTextItemName,
          cleanedItemName: freeTextItemName,
        });
      } else {
        const { cleanedItemName, itemCount, itemUnit } = parseItemName({
          freeTextItemName,
          parseItemCount,
          parseItemUnit,
        });

        onItemCountChange(itemCount);
        onItemUnitChange(itemUnit);
        onItemNameChange({ freeTextItemName, cleanedItemName });
      }
    },
    [
      isManualUpdated,
      onItemCountChange,
      onItemNameChange,
      onItemUnitChange,
      parseItemCount,
      parseItemUnit,
    ],
  );

  return (
    <div className={classNames('flex items-center', className)}>
      <div className="flex-1">
        <input
          ref={itemNameInputRef}
          type="text"
          value={itemName}
          onChange={({ target: { value } }) => onNameChange(value)}
          onKeyDown={({ key }) => {
            if (key === 'Enter') {
              onEnter();
            }
          }}
          onPaste={onPaste}
          className={classNames(
            'w-full',
            'py-1 px-2',
            'bg-transparent',
            'border border-base-300 focus:border-base-300',
            'border-t-transparent border-b-transparent',
            'focus:border-t-transparent focus:border-b-transparent',
            'bg-base-100 focus:ring-0',
            {
              'border-e-transparent focus:border-e-transparent':
                !itemName.length,
            },
          )}
          placeholder={addItemPlaceholder}
          maxLength={maxItemNameLength}
        />
      </div>
      {itemName.length ? (
        <div className="flex shrink">
          <ItemCount
            count={itemCount}
            onChange={onCountChange}
            onEnter={onEnter}
            className="text-center"
            style={{
              width: `${(itemCount.toString().length + 1) / 2}em`,
            }}
          />
          <UnitSelect
            unit={itemUnit}
            buttonClassName={classNames(
              'px-1 xs:px-1.5 py-0.5',
              'border border-transparent',
            )}
            onChange={onUnitChange}
            chevron={false}
            tight
          />
        </div>
      ) : null}
    </div>
  );
});
