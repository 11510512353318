import { MdOutlineEdit } from 'react-icons/md';
import { Button } from '@reshima/pure-ui';
import { List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { ListRenameModal } from './list-rename-modal';

export function ListRenameButton({ list }: { list: List }) {
  const name = 'ListRenameButton';
  const { button, heading, description } = useTranslations()['list-rename'];

  const { showModal } = useModal();

  const properties = { listId: list.id };

  function onClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      description,
      content: <ListRenameModal list={list} />,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button className="flex items-center gap-2" onClick={onClick} ghost>
      <MdOutlineEdit className="text-lg" />
      {button}
    </Button>
  );
}
