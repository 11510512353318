import { useCallback, useEffect, useRef } from 'react';
import { useIsOnline } from '@reshima/shared-ui';
import { AppUser, categoryItem, Item, List } from '@reshima/firebase';
import { isCategoryId } from '@reshima/category';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { useClientAuth } from '@reshima/client-auth-ui';

type UpdatesCounts = Record<string, number>;

const name = 'useUpdateLegacyItemsCategories';

export function useUpdateLegacyItemsCategories({
  list,
  items,
}: {
  list?: List;
  items?: Item[];
}) {
  const updatesCounts = useRef<UpdatesCounts>({});
  const { isOnline } = useIsOnline();
  const { user } = useClientAuth();

  const updateLegacyItemsCategories = useCallback(
    async ({
      list,
      items,
      user,
    }: {
      list: List;
      items: Item[];
      user: AppUser;
    }) => {
      const action = 'UpdateItemsLoadingCategories';

      const { id: listId } = list;
      const properties = { listId };

      const missingItemsCategories = items.filter(
        ({ id, categoryId }) =>
          categoryId &&
          !isCategoryId(categoryId) &&
          !(categoryId in list.customCategories) &&
          (updatesCounts.current[id] || 0) < 3,
      );

      if (!missingItemsCategories.length) {
        return;
      }

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        for (const item of missingItemsCategories) {
          updatesCounts.current[item.id] =
            (updatesCounts.current[item.id] || 0) + 1;
          await categoryItem({
            list,
            itemId: item.id,
            itemName: item.name,
            user,
          });
        }

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties: {
            ...properties,
            itemsCount: missingItemsCategories.length,
          },
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [],
  );

  useEffect(() => {
    if (isOnline && list && items && user) {
      updateLegacyItemsCategories({ list, items, user });
    }
  }, [isOnline, list, items, user, updateLegacyItemsCategories]);
}
