import { useCallback } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import {
  trackEvent,
  trackException,
  Action,
  ActionModifier,
} from '@reshima/telemetry';
import {
  updateCustomCategoryName,
  updateCustomCategoryIcon,
  deleteCustomCategory,
} from '@reshima/firebase';
import { Button, Input } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { CustomCategory } from '@reshima/category';
import { categoryIconMaxLength } from './constants';

export function ListCategoryItem({
  listId,
  category,
}: {
  listId: string;
  category: CustomCategory;
}) {
  const name = 'ListCategoryItem';

  const {
    categoryItem: {
      containerArilaLabel,
      nameAriaLabel,
      namePlaceholder,
      iconPlaceholder,
      iconAriaLabel,
      deleteAriaLabel,
    },
  } = useTranslations()['list-categories'];

  const onCategoryNameChange = useCallback(
    async (newName: string) => {
      const action = Action.Update;

      const properties = {
        listId,
        category,
        newName,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        await updateCustomCategoryName({
          listId,
          categoryId: category.id,
          name: newName,
        });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [category, listId],
  );

  const onCategoryIconChange = useCallback(
    async (newIcon: string) => {
      const action = Action.Update;

      const properties = {
        listId,
        category,
        newIcon,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        await updateCustomCategoryIcon({
          listId,
          categoryId: category.id,
          icon: newIcon,
        });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [category, listId],
  );

  const onDeleteClick = useCallback(async () => {
    const action = Action.Delete;

    const properties = {
      listId,
      category,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      await deleteCustomCategory({
        listId,
        categoryId: category.id,
      });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }, [category, listId]);

  return (
    <div
      className="w-full flex items-center gap-2"
      data-testid={`list-category-${category.id}`}
      aria-label={`${containerArilaLabel} - ${category.name}`}
    >
      <Input
        type="text"
        className="w-12"
        value={category.icon}
        onChange={onCategoryIconChange}
        placeholder={iconPlaceholder}
        ariaLabel={iconAriaLabel}
        maxLength={categoryIconMaxLength}
      />
      <Input
        type="text"
        className="w-full"
        value={category.name}
        onChange={onCategoryNameChange}
        placeholder={namePlaceholder}
        ariaLabel={nameAriaLabel}
      />
      <Button onClick={onDeleteClick} ghost ariaLabel={deleteAriaLabel} circle>
        <div className="p-1">
          <AiOutlineDelete />
        </div>
      </Button>
    </div>
  );
}
