import { useCallback, useState } from 'react';
import classNames from 'classnames';
import {
  getAllCategoriesItems,
  getSortedItemsByCategory,
  Item,
  List,
  updateListFixedCategoriesOrder,
} from '@reshima/firebase';
import { Button, Checkbox } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { CustomableCategoryId } from '@reshima/category';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { debouncedCallback } from '@reshima/shared';
import { ButtonLink } from '@reshima/navigation-ui';
import { SortingListItemsCategories } from './sorting-list-items-categories';

export function SortingListItems({
  list,
  items,
}: {
  list: List;
  items: Item[];
}) {
  const name = 'SortingListItems';
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [categoriesOrder, setCategoriesOrder] = useState<
    CustomableCategoryId[] | undefined
  >(list.fixedCategoriesOrder);
  const {
    categories: categoriesDictionary,
    'sorting-fixed-categories-order': {
      resetToDefault,
      showAllCategoriesLabel,
      backButton,
      backButtonAriaLabel,
    },
  } = useTranslations();

  const categoriesItems = showAllCategories
    ? getAllCategoriesItems({
        items,
        customCategories: list.customCategories,
        categoriesDictionary,
        categoriesOrder,
      })
    : getSortedItemsByCategory({
        items,
        customCategories: list.customCategories,
        categoriesDictionary,
        categoriesOrder,
      });

  const onReorder = useCallback(
    async (fixedCategoriesOrder?: CustomableCategoryId[]) => {
      const action = Action.Sort;

      const properties = {
        listId: list.id,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        await updateListFixedCategoriesOrder({
          listId: list.id,
          fixedCategoriesOrder,
        });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [list.id],
  );

  // TODO: fix this
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedReorder = useCallback(
    debouncedCallback<CustomableCategoryId[] | undefined>({
      callback: onReorder,
      delay: 500,
    }),
    [onReorder],
  );

  const updateCategoriesOrder = useCallback(
    (order?: CustomableCategoryId[]) => {
      setCategoriesOrder(order);
      debouncedReorder(order);
    },
    [debouncedReorder],
  );

  return (
    <div className="flex flex-col gap-2 mb-16">
      <div className="flex justify-between items-center">
        <Checkbox
          checked={showAllCategories}
          ariaLabel={showAllCategoriesLabel}
          label={showAllCategoriesLabel}
          onChange={() => setShowAllCategories(!showAllCategories)}
        />
        <Button
          className="px-2"
          onClick={() => updateCategoriesOrder(undefined)}
          secondary
          tight
        >
          {resetToDefault}
        </Button>
      </div>
      <SortingListItemsCategories
        categoriesItems={categoriesItems}
        onReorder={updateCategoriesOrder}
      />
      <div
        className={classNames(
          'fixed bottom-0 left-0 right-0',
          'p-4',
          'flex justify-center',
          'bg-base-100',
          'border-t border-base-300',
        )}
      >
        <ButtonLink
          href={`/list?id=${list.id}`}
          ariaLabel={backButtonAriaLabel}
        >
          {backButton}
        </ButtonLink>
      </div>
    </div>
  );
}
