import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { addItem, deleteItem, List, restoreItem } from '@reshima/firebase';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { Units } from '@reshima/shared';
import { CustomableCategoryId } from '@reshima/category';
import { useClientAuth } from '@reshima/client-auth-ui';
import { ItemActivityType } from '@reshima/list-activity-shared';
import { useListContext } from '../lists/list-context';
import {
  CategorizedMeasuredItemInput,
  CategorizedMeasuredItemInputHandler,
} from './add-item/categorized-measured-item-input';
import { useModal } from '@reshima/modals-ui';
import { AddItemsModal } from './add-item/add-items-modal';
import { getPastedItems } from './add-item/utils';

type Props = {
  list: List;
  className?: string;
  onItemNameChange: (itemName: string) => void;
};

export type AddItemFormHandler = {
  reset: () => void;
};

export const AddItemForm = forwardRef(function AddItemForm(
  { list, className, onItemNameChange }: Props,
  ref: React.Ref<AddItemFormHandler>,
) {
  const name = 'AddItemForm';
  const {
    'add-item': { addItemButton },
    'add-items-modal': { heading },
  } = useTranslations();

  const { id: listId, fixedCategoriesOrder, customCategories } = list;

  const [freeTextItemName, setFreeTextItemName] = useState('');
  const [cleanedItemName, setCleanedItemName] = useState('');
  const [itemCount, setItemCount] = useState(1);
  const [itemUnit, setItemUnit] = useState(Units.pcs);
  const [itemCategoryId, setItemCategoryId] = useState<CustomableCategoryId>();
  const categorizedMeasuredItemInputRef =
    useRef<CategorizedMeasuredItemInputHandler>(null);
  const suggestedCategoryItemNameRef = useRef('');
  const { userData } = useClientAuth();
  const { addTask } = useListContext();
  const { showModal } = useModal();

  const { parseItemCount, parseItemUnit } = userData?.settings.addingItem
    .autoComplete || {
    parseItemCount: true,
    parseItemUnit: true,
  };

  useImperativeHandle(ref, () => ({
    reset,
  }));

  const updateCleanedItemName = useCallback(
    (cleanedItemName: string) => {
      setCleanedItemName(cleanedItemName);
      onItemNameChange(cleanedItemName);
    },
    [onItemNameChange],
  );

  const reset = useCallback(() => {
    updateCleanedItemName('');
    setFreeTextItemName('');
    setItemCount(1);
    setItemUnit(Units.pcs);
    setItemCategoryId(undefined);
    categorizedMeasuredItemInputRef.current?.reset();
  }, [updateCleanedItemName]);

  const updateItemName = useCallback(
    ({
      freeTextItemName,
      cleanedItemName,
    }: {
      freeTextItemName: string;
      cleanedItemName: string;
    }) => {
      setFreeTextItemName(freeTextItemName);
      updateCleanedItemName(cleanedItemName);
    },
    [updateCleanedItemName],
  );

  async function onAddItemClick() {
    const action = 'AddItem';

    if (!cleanedItemName) return;

    const suggestedCategoryItemName = suggestedCategoryItemNameRef.current;

    const isSuggestedCategory = suggestedCategoryItemName === cleanedItemName;

    const item = {
      itemName: cleanedItemName,
      count: itemCount,
      unit: itemUnit,
      categoryId: itemCategoryId,
    };

    const properties = {
      listId,
      item,
      suggestedCategoryItemName,
      isSuggestedCategory,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    reset();

    if (!cleanedItemName) {
      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Skip,
        properties,
        start,
      });

      return;
    }

    try {
      const addedItem = addItem({
        list,
        item,
      });

      addTask({
        undo: () => deleteItem({ listId, item: addedItem }),
        redo: () => restoreItem({ listId, item: addedItem }),
        activity: {
          type: ItemActivityType.added,
          items: [addedItem],
        },
      });

      categorizedMeasuredItemInputRef.current?.focusItemName();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  const onPaste = useCallback(
    (event: React.ClipboardEvent) => {
      const items = getPastedItems({
        event,
        parseItemCount,
        parseItemUnit,
      });

      if (items.length > 1 && items.length < 100) {
        event.preventDefault();

        showModal({
          heading,
          content: (
            <AddItemsModal addTask={addTask} list={list} items={items} />
          ),
        });
      }
    },
    [addTask, heading, list, parseItemCount, parseItemUnit, showModal],
  );

  return (
    <div
      className={classNames(
        'flex w-full flex-col xs:flex-row gap-2',
        className,
      )}
      data-testid="add-item-form"
    >
      <CategorizedMeasuredItemInput
        ref={categorizedMeasuredItemInputRef}
        freeTextItemName={freeTextItemName}
        cleanedItemName={cleanedItemName}
        itemCount={itemCount}
        itemUnit={itemUnit}
        itemCategoryId={itemCategoryId}
        fixedCategoriesOrder={fixedCategoriesOrder}
        customCategories={customCategories}
        parseItemCount={parseItemCount}
        parseItemUnit={parseItemUnit}
        onItemNameChange={updateItemName}
        onItemCountChange={setItemCount}
        onItemUnitChange={setItemUnit}
        onItemCategoryIdChange={setItemCategoryId}
        onEnter={onAddItemClick}
        onPaste={onPaste}
      />
      <Button
        className="text-lg py-0.5 px-1 xs:px-2"
        tight
        disabled={!cleanedItemName}
        onClick={onAddItemClick}
      >
        {addItemButton}
      </Button>
    </div>
  );
});
