import classNames from 'classnames';
import { formatDateTime } from '@reshima/shared';
import { isItemChecked, Item, List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { ListItemIconButton } from './list-item-icon-button';
import { ListItemCheckbox } from './list-item-checkbox';
import { ListItemText } from './list-item-text';
import { ListItemDeleteButton } from './list-item-delete-button';
import { useListContext } from '../lists/list-context';

type Props = {
  list: List;
  item: Item;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
};

export function ListItem({ list, item, className, onFocus, onBlur }: Props) {
  const { added, completed } = useTranslations()['list-item'];

  const { showingItemDetails } = useListContext();

  return (
    <div
      className={classNames('flex flex-col', className)}
      data-testid={`list-item-${isItemChecked(item) ? 'checked' : 'unchecked'}`}
    >
      <div className="flex items-center gap-0.5 xs:gap-1 md:gap-1.5">
        <ListItemIconButton list={list} item={item} />
        <ListItemCheckbox className="mx-1.5 md:mx-2" list={list} item={item} />
        <ListItemText
          className="flex-1"
          list={list}
          item={item}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <ListItemDeleteButton
          className="ms-1 xs:ms-1.5 md:ms-2"
          list={list}
          item={item}
        />
      </div>
      {showingItemDetails && (
        <div className="text-xs xs:text-sm text-secondary-base-content">
          {item.checked ? completed : added}
          {formatDateTime(item.checkedUpdatedAt)}
        </div>
      )}
    </div>
  );
}
